import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header className="site-header">

    <h1 className="wrapper">
      <a className="site-title" href="/" title={siteTitle}><span className="logo"><span
        className="logo-first-letter">J</span><span className="logo-first-word">im</span><span> </span><span
        className="logo-second-letter">L</span><span className="logo-second-word">evett</span></span></a>
    </h1>

  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
