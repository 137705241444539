/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import '../style/main.scss'
import {Footer} from "./footer";

const Layout = ({ children, pageName }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          author
          email
          githubUserName
        }
      }
    }
  `)


  const metaData = data.site.siteMetadata || {}
  return (
    <div className={`page-${pageName?pageName:''}`}>
      <Header siteTitle={metaData.title} />
      <div className="page-content">
        <div className="wrapper">
          {children}
        </div>
      </div>
      <Footer siteTitle={metaData.title} siteDescription={metaData.description} twitterHandle={metaData.author} email={metaData.email} githubUserName={metaData.githubUserName} />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
